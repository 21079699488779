@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

 $sidebar-bg-color: #F3F3F7;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #444E59;
$highlight-color: #7C159F;
$icon-bg-color: #F2F5F8;
$icon-size: 30px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-stroke: 0.1px;
}

#root {
  height: 100%;
}
.card {
   background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(34,41,47,.125);
    border-radius: 0.428rem;
    box-shadow: 0 4px 24px 0 rgba(34 ,41, 47 , 0.10);
    margin: 1rem;
}
.app-container{
  display: flex;
  height: 100vh;
  background-color: inherit;
  overflow-x: hidden;

.page{
  flex:4;
  overflow-y: auto;
  overflow-x: hidden
}
}
.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }
  .pro-icon-wrapper{
     font-size: 18px !important;
  }
  .pro-inner-item{
    margin: 0 10px;
  }
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    background-color: #2E05C11A;
    border-radius: 10px;
    margin:0 10px;
  }

  .sidebar-logo {
    width: 20px;
    height: auto;
  };

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    
    // .sidebar-btn {
    //   transition: width 0.3s;
    //   width: 150px;
    //   padding: 1px 15px;
    //   border-radius: 40px;
    //   background: rgba(255, 255, 255, 0.05);
    //   color: #adadad;
    //   text-decoration: none;
    //   margin: 0 auto;
    //   height: 35px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   span {
    //     margin-left: 5px;
    //     font-size: 13px;
    //   }
    //   &:hover {
    //     color: $highlight-color;
    //   }
    // }
  }
  .sidebar-link{
    color:#444E59;
  }
  .sidebar-link::after {
    display: none;
  }
  .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
     overflow: unset!important;
  }
  .title-sidebar{
    padding:24px;
    font-weight: bold;
    font-size: 14;
    letter-spacing: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    color: #000000;
  }
  .custom-profile-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 5px;
    background-color: #4A0BB5;
    color:white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    header {
      margin-bottom: 40px;
      border-bottom: 1px solid #efefef;
      h1 {
        display: flex;
        align-items: center;
        transform: translateX(-20px);
      }
    }

    footer {
      margin-top: auto;
      color: #888;
      text-align: center;
      a {
        text-decoration: none;
        color: #888;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .social-bagdes {
    margin-top: 10px;
    img {
      margin: 5px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {
        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }
  .pro-sidebar-header{
    border-bottom:none!important;
    .sidebar-title{
      margin-left:10px;
      font-size: 24px;
      vertical-align:middle;
    }
  }
  .pro-sidebar-footer {
    border-top:none!important;
  }
  
  .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding : 8px 35px 8px 15px!important;
  }
 
  .toggle-sidebar-btn{
    z-index: 9999999;
    position: absolute;
    border-radius: 24px;
    background-color: white;
    box-shadow: 0px 2px 4px 0px #00000026;
    color:#9BA6B0;
    top: 28px;
    right: -12px;
    overflow-y: unset;
    padding: 0px 3px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .pro-sidebar:hover .toggle-sidebar-btn {
    opacity: 1;
  }

  .pro-sidebar {
    position: relative;
  }

  .pro-sidebar::after {
    position: absolute;
    content: '';
    top: 0;
    z-index: 100;
    bottom: 0;
    width: 100px;
  }

  .pro-sidebar::after:hover .toggle-sidebar-btn {
    opacity: 1;
  }

  @media (max-width: 768px) {
    // overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
    .login{
      width: 50%;
      padding:40px 17px;
    }
  }
  @media (max-width: 481px) {
    
    .login{
      width: 70%;
      
    }
  }
  
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

/* buttons */
// .btn-grad {background-image: linear-gradient(to right, #2E05C1 0%, #C8247D  51%, #7C159F  100%)}
.btn-grad {
  background-color: #4A0BB5;
  margin: 10px;
  padding: 10px 15px;
  text-align: center;
  text-transform: uppercase;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 6px;
  display: block;
  border:none;
  text-decoration: none;
  cursor: pointer;
 }

 .btn-grad:hover {
  
   color: #fff;
   text-decoration: none;
 }


.btn-grad-light {
  background-color: #4A0BB5;
    margin: 10px;
    padding: 10px 20px;
    text-align: center;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 6px;
    display: block;
    border:none;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
  }
  
  .btn-grad-light.light-square {
    margin:0px;
    padding: 9px 25px;
    border-radius:2px;
  }

  .btn-grad-light:hover {
    color: #fff;
    text-decoration: none;
  }
  .btn-grad-light svg{
    margin-right:10px;
  }


.btn-grad-light-icon {
  background-color: #4A0BB5;
  margin: 5px;
  padding: 13px 13px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 50px;
  display: block;
  border: none;
  }

  .btn-grad-light-icon:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  h4 {
    font-size: 1.1rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #5e5873;
    margin: 10px 0;
  }
  p{
    line-height: 1.5rem;
    color: #5e5873;
  }

  //side bar css
  // .pro-sidebar {
  //   color: #FFFFFF !important;
  //   .pro-menu {
  //     a {
  //       color: #FFFFFF !important;
  //     }
  //   }
  // } 

 // loader style
 // ================================================================================================

$misc-inner-max-width: 750px;

.misc-wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;

  .misc-inner {
    position: relative;
    max-width: $misc-inner-max-width;
  }

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;
    .brand-text {
      font-weight: 600;
    }
  }
}

@media (max-height: 625px) {
  .misc-wrapper {
    .misc-inner {
      margin-top: 4rem;
    }
  }
}

.transparent-text {
	font-size: 15rem;
	font-weight: 700;
  background:linear-gradient(45deg, #2E05C1 0%, #C8247D 100%, #7C159F 100%);
  background-clip:text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
	min-height: 10vh;
  }
//========================
.mt-1{
  margin-top: 1rem!important;
} 
.mt-2{
  margin-top: 1.5rem!important;
}  
.mt-3 {
  margin-top: 3rem!important;
} 

.sidebar-organization-name {
  text-align: center;
  font-weight: 600;
  padding: 4px 0px;
}

.user-menu-item {
  &:focus {
    background-color: #4A0BB5;
  }
}